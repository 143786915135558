import React, { Component } from 'react'


class NavBar extends Component {
    state = {}

    componentDidMount() {

    }

    render() {

        return (
            <>
                <nav class="navbar navbar-expand-lg fixed-top navbar-custom navbar-light sticky">
                    <div class="container">
                        <a class="navbar-brand" href="#">
                            <img src="images/logo.png" class="l-dark" height="16" alt="" />
                            <img src="images/logo-light.png" class="l-light" height="16" alt="" />
                        </a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span data-feather="menu" class="fea icon-md"></span>
                        </button>

                        <div class="collapse navbar-collapse" id="navbarCollapse">
                            <ul class="navbar-nav mx-auto">
                                <li class="nav-item">
                                    <a class="nav-link" href="#home">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#about">Biografía</a>
                                </li>

                               <li class="nav-item">
                                    <a class="nav-link" href="#">Galería</a>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link" href="#news">Noticias</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#contact">Contacto</a>
                                </li>
                            </ul>

                            <ul class="list-unstyled mb-0 mt-2 mt-sm-0 social-icon light-social-icon">
                                <li class="list-inline-item" style={{ marginRight: '7px' }}><a href="https://www.facebook.com/FacundoMoyanoOficial" target="_blank"><i class="mdi mdi-facebook"></i></a></li>

                                <li class="list-inline-item" style={{ marginRight: '7px' }}><a href="https://www.instagram.com/facundomoyanook/" target="_blank"><i class="mdi mdi-instagram"></i></a></li>
                                <li class="list-inline-item" style={{ marginRight: '7px' }}><a href="https://twitter.com/Facundo_Moyano" target="_blank"><i class="mdi mdi-twitter"></i></a></li>
                            </ul>
                        </div>
                    </div>

                </nav>
            </>
        )
    }
}


export default NavBar;

