import React, { Component } from 'react'


class Footer extends Component {
    state = {}

    componentDidMount() {

    }

    render() {

        return (
            <>
                <footer class="footer bg-dark">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-12 text-center">
                                <a href="#">
                                    <img src="images/logo-light.png" height="20" alt="" />
                                </a>
                                <p class="para-desc mx-auto mt-5">Argentino, marplatense y peronista. Hincha de Alvarado de Mar del Plata.</p>
                                <p class="para-desc mx-auto mt-5">Diputado de la Nación.</p>
                                <ul class="list-unstyled mb-0 mt-4 social-icon">


                                    <li class="list-inline-item" style={{marginRight: '7px'}}><a href="https://www.facebook.com/FacundoMoyanoOficial"><i class="mdi mdi-facebook"></i></a></li>
                                    <li class="list-inline-item" style={{marginRight: '7px'}}><a href="https://www.instagram.com/facundomoyanook/"><i class="mdi mdi-instagram"></i></a></li>
                                    <li class="list-inline-item" style={{marginRight: '7px'}}><a href="https://twitter.com/Facundo_Moyano"><i class="mdi mdi-twitter"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>

                <footer class="footer footer-bar bg-dark">
                    <div class="container text-foot text-center">
                        <p class="mb-0">© 2020 Facundo Moyano by <a href="http://www.poroto.co" target="_blank" class="">Poroto.co</a></p>
                    </div>
                </footer>

                <a href="#" class="back-to-top rounded text-center" id="back-to-top">
                    <i class="mdi mdi-chevron-up d-block"> </i>
                </a>
            </>
        )
    }
}


export default Footer;
