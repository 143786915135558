import React, { Component } from 'react'

import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import IG from './InstaFeed'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";



class Curriculum extends Component {
    state = {}

    componentDidMount() { }

    render() {
        return (
            <>
                <section class="section pb-3" id="about">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-5 col-md-5">
                                <div class="about-hero">
                                    <img src="images/11002.png" class="img-fluid mx-auto d-block about-tween position-relative" alt="" />
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div class="section-title mb-0 ml-lg-5 ml-md-3">
                                    <h4 class="title text-primary mb-3">Facundo Moyano</h4>
                                    <h6 class="designation mb-3">Argentino y <span class="text-primary">Diputado Nacional</span>
                                    </h6>
                                    <p class="text-muted">Didirigente político y gremial del peronismo bonaerense. En la actualidad se desempeña como diputado nacional​ del Frente de Todos por su provincia natal. Fue hasta el 9 de marzo de 2017 tras limitar el número de reelecciones en su sindicato Secretario General del Sindicato Único de Trabajadores de los Peajes y Afines (SUTPA), gremio que agrupa a los trabajadores de concesionarias viales.</p>
                                    <p class="text-muted">Facundo cree fuertemente en la relación de la tecnología y el trabajo para los tiempos que se aproximan, asumiendo el compromiso para lograrlo de la forma mas conveniente para el trabajador.</p>
                                    <div class="mt-4">
                                        <a href="#resume" class="btn btn-primary btn-home mouse-down">Mi Historia</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid mt-100 mt-60">
                        <div class="rounded py-md-5 py-4" style={{ background: "url(images/bannerOK.jpg) center center", backgroundSize: 'cover' }}>
                            <div class="container">
                                <div class="row" id="counter">
                                    <div class="col-12">
                                        <div class="counter-box rounded py-3 text-center">
                                            <div class="counter-icon">
                                                <i data-feather="smile" class="fea icon-md text-primary"></i>
                                            </div>
                                            <h2 class="mt-3 text-white title-dark">Tecnología aplicada al trabajo</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="container mt-100 mt-60">
                        <div class="row">
                            <div class="col-12 col-md-6 text-center">
                                <div class="section-title">
                                    <div class="titles">
                                        <h4 class="text-uppercase mb-4 pb-4"><img src="images/twitter.png" width="90px" /></h4>

                                        {/*     <TwitterFollowButton
                                            screenName={'Facundo_Moyano'}
                                        />    */}
                                        <TwitterTweetEmbed
                                            tweetId={'1296877577827758080'}
                                        />
                                        {/* <TwitterTimelineEmbed
                                            sourceType="profile"
                                            screenName="Facundo_Moyano"
                                            options={{ height: '370px' }}
                                        />
                                       */}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 text-center">
                                <div class="section-title">
                                    <div class="titles">
                                        <h4 class=" text-uppercase mb-4 pb-4"><img src="images/instagram.png" width="90px" /></h4>
                                        <BrowserView>
                                            <IG account="facundomoyanook" numberOfMediaElements={9} />
                                        </BrowserView>
                                        <MobileView>
                                            <IG account="facundomoyanook" numberOfMediaElements={3} />
                                        </MobileView>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}


export default Curriculum;






