import React, { Component } from 'react'

import InstagramEmbed from 'react-instagram-embed';


class Resto extends Component {
    state = {}

    componentDidMount() { }

    render() {
        return (
            <>
                <section class="section" id="resume">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-12 text-center">
                                <div class="section-title">
                                    <div class="title">
                                        <h4 class="title title-line text-uppercase mb-4 pb-4">Trayectoria</h4>
                                        <img src="images/iconito.png" style={{ marginTop: '-64px' }} />
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div class="row">
                            <div class="col-12">
                                <div class="main-icon rounded-pill text-center mt-4 pt-2" style={{ backgroundImage: 'url(images/trayectoria.png)', border: '0px solid #ced4da', height: '185px', width: '190px', lineHeight: '0', textAlign: 'center' }}>
                                </div>
                                <div class="timeline-page pt-2 position-relative">
                                    <div class="timeline-item mt-4">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="duration date-label-left border rounded p-2 pl-4 pr-4 position-relative shadow text-left">2009 - 2017</div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="event event-description-right rounded p-4 border float-left text-left">
                                                    <h5 class="title mb-0 text-capitalize">Secretario General</h5>
                                                    <small class="company">Sindicato Único de Trabajadores de los Peajes y Afines</small>
                                                    <p class="timeline-subtitle mt-3 mb-0 text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="timeline-item mt-4">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2">
                                                <div class="event event-description-left rounded p-4 border float-left text-right">
                                                    <h5 class="title mb-0 text-capitalize">Secretario General</h5>
                                                    <small class="company">Sindicato Único de Trabajadores de los Peajes y Afines</small>
                                                    <p class="timeline-subtitle mt-3 mb-0 text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-2 order-1">
                                                <div class="duration duration-right rounded border p-2 pl-4 pr-4 position-relative shadow text-left">2009 - 2017</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="timeline-item mt-4">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="duration date-label-left border rounded p-2 pl-4 pr-4 position-relative shadow text-left">2012 - 2010</div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="event event-description-right rounded p-4 border float-left text-left">
                                                    <h5 class="title mb-0 text-capitalize">Secretario General</h5>
                                                    <small class="company">Sindicato Único de Trabajadores de los Peajes y Afines</small>
                                                    <p class="timeline-subtitle mt-3 mb-0 text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




                <section class="section bg-light pb-3" id="news">
                    <div class="container">
                        <div class="row justify-content-center">
                            
                        <div class="col-12 text-center">
                                <div class="section-title">
                                    <div class="title">
                                        <h4 class="title title-line text-uppercase mb-4 pb-4">Novedades</h4>
                                        <img src="images/iconito.png" style={{ marginTop: '-64px' }} />
                                    </div>
                                    <p class="text-muted mx-auto para-desc mb-0">Enterate de las últimas novedades</p>
                                </div>
                            </div>
                                    
                            
                            
                        </div>


                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div class="blog-post rounded shadow">
                                    <img src="images/blog01.jpg" class="img-fluid rounded-top" alt="" />
                                    <div class="content pt-4 pb-4 p-3">
                                        <ul class="list-unstyled d-flex justify-content-between post-meta">
                                            <li><i data-feather="user" class="fea icon-sm mr-1"></i><a href="javascript:void(0)"
                                                class="text-dark">Facundo Moyano</a></li>
                                            <li><i data-feather="tag" class="fea icon-sm mr-1"></i><a href="javascript:void(0)"
                                                class="text-dark">Noticia</a></li>
                                        </ul>
                                        <h5 class="mb-3"><a href="page-blog-detail.html" class="title text-dark">
                                            Ampliación del plan de moratoria para aliviar a contribuyentes
                            </a></h5>
                                        <ul class="list-unstyled mb-0 pt-3 border-top d-flex justify-content-between">
                                            <li><a href="javascript:void(0)" class="text-dark">Leer más <i
                                                data-feather="chevron-right" class="fea icon-sm"></i></a></li>
                                            <li><i class="mdi mdi-calendar-edit mr-1"></i>10 abril, 2020</li>
                                        </ul>
                                    </div>

                                </div>

                            </div>


                            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div class="blog-post rounded shadow">
                                    <img src="images/blog02.png" class="img-fluid rounded-top" alt="" />
                                    <div class="content pt-4 pb-4 p-3">
                                        <ul class="list-unstyled d-flex justify-content-between post-meta">
                                            <li><i data-feather="user" class="fea icon-sm mr-1"></i><a href="javascript:void(0)"
                                                class="text-dark">Facundo Moyano</a></li>
                                            <li><i data-feather="tag" class="fea icon-sm mr-1"></i><a href="javascript:void(0)"
                                                class="text-dark">Noticia</a></li>
                                        </ul>
                                        <h5 class="mb-3"><a href="page-blog-detail.html" class="title text-dark">Tenemos que ocuparnos del avance de la tecnología en el mundo laboral con responsabilidad.</a></h5>
                                        <ul class="list-unstyled mb-0 pt-3 border-top d-flex justify-content-between">
                                            <li><a href="javascript:void(0)" class="text-dark">Leer más <i
                                                data-feather="chevron-right" class="fea icon-sm"></i></a></li>
                                            <li><i class="mdi mdi-calendar-edit mr-1"></i>10 abril, 2020</li>
                                        </ul>
                                    </div>

                                </div>

                            </div>


                            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div class="blog-post rounded shadow">
                                    <img src="images/blog03.jpg" class="img-fluid rounded-top" alt="" />
                                    <div class="content pt-4 pb-4 p-3">
                                        <ul class="list-unstyled d-flex justify-content-between post-meta">
                                            <li><i data-feather="user" class="fea icon-sm mr-1"></i><a href="javascript:void(0)"
                                                class="text-dark">Facundo Moyano</a></li>
                                            <li><i data-feather="tag" class="fea icon-sm mr-1"></i><a href="javascript:void(0)"
                                                class="text-dark">Noticia</a></li>
                                        </ul>
                                        <h5 class="mb-3"><a href="page-blog-detail.html" class="title text-dark">Hace 10 años nuestro país se transformaba en el primero en Latinoamérica en sancionar una Ley de matrimonio igualitario.</a></h5>
                                        <ul class="list-unstyled mb-0 pt-3 border-top d-flex justify-content-between">
                                            <li><a href="javascript:void(0)" class="text-dark">Leer más <i
                                                data-feather="chevron-right" class="fea icon-sm"></i></a></li>
                                            <li><i class="mdi mdi-calendar-edit mr-1"></i>10 abril, 2020</li>
                                        </ul>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>


     

                    <div class="container-fluid mt-100 mt-60">
                        <div class="rounded py-md-5 py-4" style={{ background: "url(images/bannerOK.jpg) center center", backgroundSize: 'cover' }}>
                            <div class="container">
                                <div class="row" id="counter">
                                    <div class="col-12">
                                        <div class="counter-box rounded py-3 text-center">
                                            <div class="counter-icon">
                                                <i data-feather="smile" class="fea icon-md text-primary"></i>
                                            </div>
                                            <h4 class="text-light title-dark">Entre todos seguimos trabajando por una Argentina inclusiva, con desarrollo y empleo.</h4>                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>



                <section class="section pb-0" id="contact">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-12 text-center">
                            <div class="col-12 text-center">
                                <div class="section-title">
                                    <div class="title">
                                        <h4 class="title title-line text-uppercase mb-4 pb-4">Dejanos tu mensaje</h4>
                                        <img src="images/iconito.png" style={{ marginTop: '-64px' }} />
                                    </div>
                                </div>
                            </div>
                            </div>

                        </div>
                    </div>
                </section>


                <section class="section pt-5 mt-3">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <div class="custom-form mb-sm-30">
                                    <div id="message"></div>
                                    <form method="post" action="php/contact.php" name="contact-form" id="contact-form">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-6">
                                                        <div class="form-group">
                                                            <input name="name" id="name" type="text"
                                                                class="form-control border rounded" placeholder="Nombre" />
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12 col-md-6">
                                                        <div class="form-group">
                                                            <input name="email" id="email" type="email"
                                                                class="form-control border rounded" placeholder="Email" />
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="form-group">
                                                            <input name="subject" id="subject" class="form-control border rounded"
                                                                placeholder="Asunto" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <textarea name="comments" id="comments" rows="4"
                                                        class="form-control border rounded" placeholder="Mensaje"></textarea>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12 text-right">
                                                <input type="submit" id="submit" name="send" class="submitBnt btn btn-primary"
                                                    value="Enviar Mensaje" />
                                                <div id="simple-msg"></div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </>
        )
    }
}


export default Resto;

