import React from 'react';
import logo from './logo.svg';

import NavBar from './components/NavBar';
import Slider from './components/Slider';
import Cv from './components/Curriculum';
import Resto from './components/Resto';
import Footer from './components/Footer';


import './assets/css/bootstrap.min.css';
import './assets/css/magnific-popup.css';
import './assets/css/materialdesignicons.min.css';

import './assets/css/owl.carousel.min.css';
import './assets/css/owl.theme.css';
import './assets/css/owl.transitions.css';
import './assets/css/style.css';
import './assets/css/colors/default.css';






function App() {
  return (
    <>
      <NavBar />
      <Slider/>
      <Cv/>
      <Resto/>
      <Footer />
      
    </>
  );
}

export default App;
