import React, { Component } from 'react'
import Particles from 'react-particles-js';


class Slider extends Component {
    state = {}

    componentDidMount() { }

    render() {
        return (
            <>

                <section class="bg-home d-table w-100"
                    style={{ backgroundImage: 'url(images/fondo2.jpg)' }} id="home">
                 
                    <div class="bg-overlay">
                    </div>
                    <div class="container position-relative" style={{ zIndex: '1' }}>
                        <div class="row mt-5 mt-sm-0 justify-content-center">
                            <div class="col-lg-12 text-center">
                                <div class="title-heading">
                                    <img src="images/Perfil_web.jpg" class="img-fluid rounded-circle" alt="" />
                                    <h3 class="heading text-light title-dark mt-3" style={{ color: 'white' }}>Facundo Moyano <img style={{ width: '32px', height: '32px', border: 'none' }} src="images/verificado.png" /></h3>
                                    <p class="para-desc mx-auto text-white-50" style={{ color: '#rgb(255 255 255 / 0.8)!important' }}>Argentino, marplatense y peronista. Hincha de Alvarado de Mar del Plata.</p>
                                    <p class="para-desc mx-auto text-white-50" style={{ color: '#rgb(255 255 255 / 0.8)!important' }}>Diputado de la Nación.</p>
                                    <div class="mt-4">
                                        <a href="#news" class="btn btn-primary btn-home rounded">Mi Agenda <i data-feather="download" class="fea icon-sm"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="#about" data-scroll-nav="1" class="mouse-icon mouse-icon-white rounded-pill bg-transparent mouse-down">
                        <span class="wheel position-relative d-block mover"></span>
                    </a>
                </section>

            </>
        )
    }
}


export default Slider;
